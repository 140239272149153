import {
  Card,
  Text,
  IconGift,
  Button,
  COLOR_INFO_50,
  Conditional,
} from '@consumidor-positivo/aurora';
import fireEmoji from '../../assets/icons/emoji-fire.svg';
import classNames from 'classnames';
import { AuroraCardOfferProps } from './types';
import './styles.scss'

export default function AuroraCardOffer({
  onClickBtn,
  loading,
  offer,
  location,
  index,
  handleDetailRedirect,
  isModalCard,
  style
}: AuroraCardOfferProps) {
  const { content, shDecision } = offer;
  const { preApproved, limit } = shDecision;
  const {
    image_logo,
    category,
    title,
    highlights_ac,
    button_text,
    description,
    detail_page,
    partner_friendly_name,
  } = content;

  const offerEmphasis = [
    {
      title: category === 'loans' ? 'Valor' : 'Anuidade',
      description: highlights_ac[0]?.description,
    },
    {
      title: category === 'loans' ? 'Taxas' : 'Benefício',
      description: highlights_ac[1]?.description,
    },
  ];

  const cardClasNames =  classNames("offer-card", {
    "with-limit": Boolean(limit),
  });

  const edditedLimit = new Intl.NumberFormat('pt-BR', {
    maximumFractionDigits: 0,
  }).format(limit || 0);

  const hasLimitOrApproval = limit || preApproved;
  const tagColor = hasLimitOrApproval ? 'primary' : 'secondary';
  const tagIcon = hasLimitOrApproval ? (
    <img src={fireEmoji} width={18} />
  ) : (
    <IconGift rawColor={COLOR_INFO_50} size="small" />
  );
  const tagText = hasLimitOrApproval
    ? 'Chance alta de aprovação'
    : content?.feature_information;

  return (
    <div style={style}>
      <Card.Root maxWidth={308}>
        <Card.Container gap={16} alignItems="center">
          <Card.Tag color={tagColor} icon={tagIcon}>
            {tagText}
          </Card.Tag>
          <Conditional
            condition={!isModalCard}
            renderIf={
              <>
                <Card.Image height={72} src={image_logo?.filename} />
                <Card.Container alignItems="center">
                  <Conditional
                    condition={Boolean(limit)}
                    renderIf={
                      <>
                        <Text variant="body-medium" weight="semibold">
                          {title}
                        </Text>
                        <Text variant="heading-small" weight="bold">
                          Limite de R$ {edditedLimit}
                        </Text>
                      </>
                    }
                    renderElse={
                      <Text variant="heading-micro" weight="bold">
                        {title}
                      </Text>
                    }
                  />
                </Card.Container>
                <Card.Emphasis height={111} content={offerEmphasis} />
                <Card.Container gap={8}>
                  <Button
                    expand="x"
                    loading={loading}
                    onClick={() => onClickBtn(offer, location, index)}
                  >
                    {button_text}
                  </Button>
                  <Conditional
                    condition={detail_page && partner_friendly_name}
                    renderIf={
                      <Button
                        expand="x"
                        type="outlined"
                        onClick={handleDetailRedirect}
                      >
                        Ver detalhes
                      </Button>
                    }
                  />
                </Card.Container>
              </>
            }
            renderElse={
              <>
                <Card.Container direction="row" alignItems="center" gap={16}>
                  <Card.Image height={64} src={image_logo?.filename} />
                  <Conditional
                    condition={Boolean(limit)}
                    renderIf={
                      <Card.Container>
                        <Text
                          variant="body-small"
                          color="secondary"
                          weight="semibold"
                        >
                          {title}
                        </Text>
                        <Text variant="heading-small" weight="bold">
                          Limite de R${edditedLimit}
                        </Text>
                      </Card.Container>
                    }
                    renderElse={
                      <Card.Container>
                        <Text variant="heading-micro" weight="bold">
                          {title}
                        </Text>
                        <Text variant="body-small" color="secondary">
                          {description}
                        </Text>
                      </Card.Container>
                    }
                  />
                </Card.Container>
                <Card.Container direction="row" alignItems="center" gap={8}>
                  <Conditional
                    condition={detail_page && partner_friendly_name}
                    renderIf={
                      <Button
                        type="outlined"
                        expand="x"
                        onClick={handleDetailRedirect}
                      >
                        Ver detalhes
                      </Button>
                    }
                  />
                  <Button
                    onClick={() => onClickBtn(offer, location, index)}
                    loading={loading}
                    expand="x"
                  >
                    {button_text}
                  </Button>
                </Card.Container>
              </>
            }
          />
        </Card.Container>
      </Card.Root>
    </div>
  );
}
