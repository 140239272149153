import { Card, Skeleton } from '@consumidor-positivo/aurora';

export function FeaturedOfferSkeleton() {
  return (
    <div>
      <Card.Root maxWidth={272}>
        <Card.Container gap={16} alignItems="center">
          <Skeleton height={29} block />
          <Skeleton height={72} width={88} />
          <Card.Container alignItems="center">
            <Skeleton width={194} />
          </Card.Container>
          <Skeleton shape="square" height={111} block />
          <Card.Container gap={8}>
            <Skeleton height={48} block shape="circle" />
            <Skeleton height={48} block shape="circle" />
          </Card.Container>
        </Card.Container>
      </Card.Root>
    </div>
  );
}
