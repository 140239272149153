import { useEffect, useRef } from 'react';
import {
  Button,
  Conditional,
  IconAlertOctagon,
} from '@consumidor-positivo/aurora';
import CardSection from '../CardSection';
import Message from '@libs/atlas/components/Message';
import classNames from 'classnames';
import PaymentSection from '../PaymentSection';
import { DebtData } from '@common/hooks/useDebts/types';
import { Billet, ClosedAgreementProps } from '../../../types';
import OrientationCard from '../OrientationCard';
import useShowPaymentMethodSelectorExperiment from '@common/abtest/experiments/useShowPaymentMethodSelectorExperiment';

interface PaymentDataProps {
  handleSeeAgreementDetails: () => void;
  isFirstInstallmentPaid: boolean;
  closedAgreement: ClosedAgreementProps;
  debtData: DebtData;
  hasBillet: boolean;
  pixCode: string;
  isPixExternal: boolean;
  billet: Billet;
  isPixSuccess: boolean;
  qrCodeUrl: string;
  handleCopyPixCode: () => void;
  pixText: string;
  firstInstallmentDate: string;
  warningRef: React.MutableRefObject<HTMLElement | null>;
  showPollingBilletComp: boolean;
  handleGeneratePixCode: any;
  isLoadingTryAgain: boolean;
}

const PaymentData = ({
  handleSeeAgreementDetails,
  isFirstInstallmentPaid,
  closedAgreement,
  debtData,
  warningRef,
  hasBillet,
  pixCode,
  isPixExternal,
  billet,
  isPixSuccess,
  qrCodeUrl,
  handleCopyPixCode,
  pixText,
  firstInstallmentDate,
  isLoadingTryAgain,
  showPollingBilletComp,
  handleGeneratePixCode,
}: PaymentDataProps) => {
  const warningData = !isFirstInstallmentPaid
    ? `A confirmação do pagamento pode levar <b>até 5 dias úteis</b>.`
    : `Se houver uma restrição no seu nome, <b>ela será removida em até 5 dias úteis</b> após a confirmação do pagamento.`;

  const { shouldHidePixSection, shouldHideBilletSection } =
    useShowPaymentMethodSelectorExperiment({
      partnerIdentifier: debtData?.partnerIdentifier,
      paymentMethodName: closedAgreement?.paymentOption?.paymentMethodName,
      singlePaymentMethod: closedAgreement?.singlePaymentMethod,
    });

  const showPixErrorWarning =
    !!closedAgreement?.flowWithPix &&
    !pixCode &&
    !isPixExternal &&
    hasBillet &&
    !isLoadingTryAgain &&
    !shouldHidePixSection;

  const showPaymentDataDiv =
    !shouldHideBilletSection || (!shouldHidePixSection && !!pixCode); // TEMP: use only when pixleto test is active

  const isSecondAttempt = useRef(false);

  useEffect(() => {
    if (isLoadingTryAgain) isSecondAttempt.current = true;
  }, [isLoadingTryAgain]);

  return (
    <div className="closed-agreement-page-v2__container">
      <Conditional
        condition={showPixErrorWarning}
        renderIf={
          <Message
            customClass="pixleto-error-message"
            type="custom"
            icon={<IconAlertOctagon />}
          >
            Tivemos um problema ao gerar seu código Pix.{' '}
            <Conditional
              condition={!isSecondAttempt.current}
              renderIf={
                <>
                  {shouldHideBilletSection
                    ? 'Por favor, tente novamente.'
                    : ' Tente novamente e se não funcionar, faça o pagamento via boleto.'}

                  <div>
                    <Button
                      type="ghost"
                      onClick={() =>
                        handleGeneratePixCode({ isTryAgain: true })
                      }
                    >
                      Gerar Pix novamente
                    </Button>
                  </div>
                </>
              }
              renderElse={
                <>
                  <b>Aguarde até 24h para receber o código via e-mail</b>
                  {shouldHideBilletSection
                    ? '.'
                    : ' ou faça o pagamento via boleto.'}
                </>
              }
            />
          </Message>
        }
      />

      <Conditional
        condition={!isFirstInstallmentPaid && showPaymentDataDiv}
        renderIf={
          <PaymentSection
            isBilletPollingActive={showPollingBilletComp}
            closedAgreement={closedAgreement}
            warningRef={warningRef}
            pixCode={pixCode}
            hasBillet={hasBillet}
            isPixExternal={isPixExternal}
            billet={billet}
            qrCodeUrl={qrCodeUrl}
            isPixSuccess={isPixSuccess}
            handleCopyPixCode={handleCopyPixCode}
            pixText={pixText}
            isLoadingTryAgain={isLoadingTryAgain}
          />
        }
      />

      <Conditional
        condition={!hasBillet && !pixCode && !showPollingBilletComp}
        renderIf={
          <OrientationCard hasDebitAccount={false} pix={isPixExternal} />
        }
      />

      <CardSection
        withoutPaymentData={!hasBillet && !pixCode && !showPollingBilletComp}
        firstInstallmentDate={firstInstallmentDate}
        isFirstInstallmentPaid={isFirstInstallmentPaid}
        debtData={debtData}
      />

      <Message
        type="warning"
        customClass={classNames('payment-info', {
          'payment-info__success': isFirstInstallmentPaid,
        })}
        dangerouslySetInnerHTML={warningData}
      />

      <Button
        className="closed-agreement-page-v2__btn-details"
        expand="x"
        type="outlined"
        onClick={handleSeeAgreementDetails}
      >
        Ver detalhes do acordo
      </Button>
    </div>
  );
};

export default PaymentData;
