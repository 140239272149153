import { Conditional } from '@consumidor-positivo/aurora';
import { OfferModalDigio } from '../OfferModal/Digio';
import useGetOffers from '@common/hooks/useOffers';

import { FeaturedOfferTemplate as Template } from './Template';
import { FeaturedOfferSkeleton as Skeleton } from './components/Skeleton';
import { FeaturedOffersCarousel as Carousel } from './components/Carousel';
import { OfferFeaturedCard as Card } from './components/Card';

import { useOfferFeatured } from './hooks';
import { OfferFeaturedProps } from './types';

export const OfferFeatured = ({
  offers,
  location,
  onClickOffersCTA,
  blockTitle,
  decideFinish = true,
}: OfferFeaturedProps) => {
  const { changeCarousel, onChangeIndexCarousel } = useOfferFeatured(offers);
  const {
    onClickOffer,
    redirectToOffers,
    onCloseDigioModal,
    openDigioModal,
    shLoading,
    noOffer,
  } = useGetOffers();

  const title = blockTitle
    ? blockTitle
    : 'Você tem ofertas liberadas te esperando';

  return (
    <>
      <section>
        <Template
          title={title}
          noOffer={noOffer}
          onClickMoreOffersBtn={() => {
            redirectToOffers();
            onClickOffersCTA && onClickOffersCTA();
          }}
        >
          <Conditional
            condition={shLoading || !decideFinish}
            renderIf={<Skeleton />}
            renderElse={
              <Carousel onChangeIndexCarousel={onChangeIndexCarousel}>
                {offers?.slice(0, 3).map((offer, index) => (
                  <Card
                    offer={offer}
                    index={index}
                    location={location}
                    changeCarousel={changeCarousel}
                    onClickOffer={() => onClickOffer(offer, location, index)}
                  />
                ))}
              </Carousel>
            }
          />
        </Template>
      </section>
      <OfferModalDigio
        location={location}
        visible={openDigioModal}
        onCloseModal={onCloseDigioModal}
      />
    </>
  );
};
