import { useNavigate } from 'react-router-dom';
import { Button, Conditional, Text } from '@consumidor-positivo/aurora';
import { pageNames } from '@common/routes/pageNames';
import HeadingSection from './components/HeadingSection';
import useClosedAgreement from '../hooks';

import { OfferFeatured } from '@common/components/OfferFeatured';
import useGetOffers from '@common/hooks/useOffers';
import { useDeviceSize } from '@libs/utils/hooks/useDeviceSize';
import PaymentData from './components/PaymentData';
import OrientationCard from './components/OrientationCard';
import InstallmentDataCard from './components/InstallmentDataCard';
import { useEffect } from 'react';
import './styles.scss';

const ClosedAgreementV2 = () => {
  const {
    closedAgreement,
    debtData,
    qrCodeUrl,
    isPixExternal,
    pixText,
    billet,
    hasBillet,
    handleCopyPixCode,
    isPixSuccess,
    paymentStatus,
    pixCode,
    warningRef,
    selectedBankAccount,
    firstInstallmentDate,
    showPollingBilletComp,
    handleGeneratePixCode,
    isLoadingTryAgain,
  } = useClosedAgreement();

  const hasDebitAccount = !!closedAgreement?.bankData;

  const navigate = useNavigate();
  const { featuredOffers, decideFinish } = useGetOffers({ callSHDecide: true });

  const handleSeeAgreementDetails = () => {
    navigate(
      `${pageNames.myAgreementsDetail.path.replace(
        ':agreementId',
        `${closedAgreement.agreement?.id}`
      )}`
    );
  };

  const { isMobile } = useDeviceSize();
  const isTablet = window.innerWidth >= 720 && window?.innerWidth < 1024;

  const isFirstInstallmentPaid = paymentStatus === 'pixPaid';

  useEffect(() => {
    document.body.classList.toggle('no-scroll', false);
  }, []);

  return (
    <div className="closed-agreement-page-v2">
      <Conditional
        condition={!selectedBankAccount}
        renderIf={
          <div className="closed-agreement-page-v2__payment-page">
            <HeadingSection
              withoutPaymentData={!hasBillet && !pixCode}
              isFirstInstallmentPaid={isFirstInstallmentPaid}
              debtData={debtData}
              firstInstallmentDate={firstInstallmentDate}
            />

            <PaymentData
              hasBillet={hasBillet}
              showPollingBilletComp={showPollingBilletComp}
              pixCode={pixCode}
              isPixExternal={isPixExternal}
              billet={billet}
              isPixSuccess={isPixSuccess}
              qrCodeUrl={qrCodeUrl}
              handleCopyPixCode={handleCopyPixCode}
              pixText={pixText}
              warningRef={warningRef}
              closedAgreement={closedAgreement}
              isFirstInstallmentPaid={isFirstInstallmentPaid}
              debtData={debtData}
              handleSeeAgreementDetails={handleSeeAgreementDetails}
              firstInstallmentDate={firstInstallmentDate}
              handleGeneratePixCode={handleGeneratePixCode}
              isLoadingTryAgain={isLoadingTryAgain}
            />
          </div>
        }
        renderElse={
          <>
            <HeadingSection
              hasDebitAccount={hasDebitAccount}
              debtData={debtData}
              firstInstallmentDate={firstInstallmentDate}
            />
            <div className="closed-agreement-page-v2__debit-account-container">
              <div className="closed-agreement-page-v2__heading details">
                <Text variant="heading-small" weight="bold">
                  Detalhes do acordo
                </Text>
                <Conditional
                  condition={!isMobile}
                  renderIf={
                    <Button
                      className="closed-agreement-page-v2__btn-details"
                      expand="x"
                      type="outlined"
                      onClick={handleSeeAgreementDetails}
                    >
                      Ver detalhes do acordo
                    </Button>
                  }
                />
              </div>

              <Conditional
                condition={!isMobile && !isTablet}
                renderIf={<div className="closed-agreement-page-v2__divider" />}
              />

              <div className="closed-agreement-page-v2__content">
                <OrientationCard
                  pix={false}
                  hasDebitAccount={!!closedAgreement?.bankData}
                />

                <Conditional
                  condition={!!selectedBankAccount}
                  renderIf={
                    <div className="closed-agreement-page-v2__debit-account-card">
                      <Text variant="heading-micro" weight="bold">
                        Conta cadastrada
                      </Text>
                      <div className="closed-agreement-page-v2__installment-card-line">
                        <Text color="secondary">Banco:</Text>
                        <Text color="secondary" weight="bold">
                          {selectedBankAccount?.bank}
                        </Text>
                      </div>
                      <div className="closed-agreement-page-v2__divider" />

                      <div className="closed-agreement-page-v2__installment-card-line">
                        <Text color="secondary">Agência:</Text>
                        <Text color="secondary" weight="bold">
                          {selectedBankAccount?.account}
                        </Text>
                      </div>
                      <div className="closed-agreement-page-v2__divider" />
                      <div className="closed-agreement-page-v2__installment-card-line">
                        <Text color="secondary">Conta:</Text>
                        <Text color="secondary" weight="bold">
                          {selectedBankAccount?.agency}
                        </Text>
                      </div>
                    </div>
                  }
                />

                <InstallmentDataCard
                  firstInstallmentDate={firstInstallmentDate}
                  debtData={debtData}
                  hasDebitAccount={hasDebitAccount}
                />
              </div>

              <Conditional
                condition={!!isMobile}
                renderIf={
                  <Button
                    className="closed-agreement-page-v2__btn-details"
                    expand="x"
                    type="outlined"
                    onClick={handleSeeAgreementDetails}
                  >
                    Ver detalhes do acordo
                  </Button>
                }
              />

              <div className="closed-agreement-page-v2__divider offers" />

              <div className="closed-agreement-page-v2__offers">
                <OfferFeatured
                  location="ac-acordo-fechado-destaque"
                  decideFinish={decideFinish}
                  offers={featuredOffers}
                  blockTitle="Você desbloqueou novas ofertas!"
                  onClickOffersCTA={() => navigate(pageNames.offers.path)}
                />
              </div>
            </div>
          </>
        }
      />
    </div>
  );
};

export default ClosedAgreementV2;
