import useApiPixExperiment from '@common/abtest/experiments/useApiPixExperiment';
import { useEffect, useState } from 'react';

interface URIErrorsePixExperimentProps {
  // TODO: adjust types
  debt: any;
  paymentOption: any;
}

const partnersWithPixInternal = [
  'btg',
  'cielo',
  'colombo',
  'credigy',
  'credsystem',
  'credz',
  'liftcred',
  'tribanco',
  'uzecartoes',
  'neon',
  'via',
  'itapeva',
  'sky',
  'riachuelo',
  'nubank'
]; // Partners who must show Pix without the need for testing.

export const partnersWithPixExternal = ['recovery', 'itau', 'carrefour']; // Partners with Pix integration through external service.

const partnersForTest = ['carrefour']; // Partners requiring Pix testing.

// const partnersWithServerGeneratedPix = [ >>> NEXT CHAPTER
//   'cencosud',
//   'credz',
//   'cielo',
//   'neon',
// ]


const usePixExperiment = ({
  debt,
  paymentOption,
}: URIErrorsePixExperimentProps) => {
  const [flowWithPix, setFlowWithPix] = useState<boolean | undefined>(false);
  const [isPixExternal, setIsPixExternal] = useState<boolean>(false);
  const { resultIsNewApiPix } = useApiPixExperiment()

  const { partnerIdentifier } = debt?.partner || {};

  interface resultsPartnersForTestProps {
    [key: string]: boolean;
  }

  const resultsPartnersForTest: resultsPartnersForTestProps = {
    // add here the partner to be tested with result variable, example:
    // nubank: resultIsWithPixNubankCards
  };

  const handlePix = (): boolean | undefined => {
    const valorEntrada = paymentOption?.entryValue || paymentOption?.valorEntrada;

    const isAtivossa = partnerIdentifier === 'ativossa';

    const hasPixInAtivossa = isAtivossa && valorEntrada < 1000 && resultIsNewApiPix;
    const isPixExternal = partnersWithPixExternal?.includes(partnerIdentifier);
    const isPixInternal = partnersWithPixInternal?.includes(partnerIdentifier) && resultIsNewApiPix;

    setIsPixExternal(isPixExternal);

    if (hasPixInAtivossa || isPixInternal || isPixExternal) return true;

    const isPartnerForTest = partnersForTest?.includes(partnerIdentifier);

    if (isPartnerForTest) return resultsPartnersForTest[partnerIdentifier];

    return false;
  };

  useEffect(() => {
    if (paymentOption) {
      setFlowWithPix(() => handlePix());
    }
  }, [paymentOption]);

  return {
    flowWithPix,
    isPixExternal,
   // shouldGetInternalPix: partnersWithServerGeneratedPix.includes(partnerIdentifier) >>> NEXT CHAPTER
  };
};

export default usePixExperiment;
