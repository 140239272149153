import { OfferProps } from '@common/hooks/useOffers/types';
import { useEffect, useState } from 'react';
import { TypesSpineticChangeEvent } from 'react-spinetic/types';

export function useOfferFeatured(offers: OfferProps[]) {
  const [changeCarousel, setChangeCarousel] =
    useState<TypesSpineticChangeEvent>();

  function onChangeIndexCarousel(e: TypesSpineticChangeEvent) {
    setChangeCarousel(e);
  }

  return {
    changeCarousel,
    onChangeIndexCarousel,
  };
}
