import CardOffer from '@common/components/CardOffer';
import { Conditional } from '@consumidor-positivo/aurora';
import { OfferFeaturedCardProps } from '../../types';
import { useOfferFeaturedCard } from '../../hooks/useOfferFeaturedCard';
import { useDogxContext } from '@libs/dogx';
import AuroraCardOffer from '@common/components/AuroraCardOffer';
import './styles.scss';

export function OfferFeaturedCard(props: OfferFeaturedCardProps) {
  const { offer, changeCarousel } = props;

  if (!offer || !offer.content) return null;

  const { thisBtnLoading, onClickBtn, handleDetailRedirect, observerRef } =
    useOfferFeaturedCard(props);
  const { content, shDecision } = offer;
  const { preApproved, limit, value } = shDecision || {};

  const {
    image_logo,
    category,
    title,
    feature_information,
    highlights_ac,
    partner,
    button_text,
    description,
    subtitle,
    subject_to_credit_approval,
    slug,
  } = content;

  const featureInformationDefault = 'Oferta especial';
  const jeittoValue = value || '500';
  const editedDescription =
    partner === 'jeitto'
      ? description?.replace('(value)', jeittoValue)
      : description;
  const isSmallImage = image_logo?.filename.indexOf('/64x88/') > -1;

  //#region TEST AB - New Offer Card Layout
  const { getExperimentVariant } = useDogxContext();
  const hasNewCardLayoutTest =
    getExperimentVariant('dx-new-card-layout') === 'has-new-layout';
  //#endregion

  return (
    <div ref={observerRef}>
      <Conditional
        condition={hasNewCardLayoutTest}
        renderIf={
          <AuroraCardOffer
            offer={offer}
            loading={thisBtnLoading}
            onClickBtn={onClickBtn}
            handleDetailRedirect={handleDetailRedirect}
            location={props.location}
            index={props.index}
            style={{ marginRight: '16px', width: '272px' }}
          />
        }
        renderElse={
          <CardOffer.Root place="featured">
            <CardOffer.Tag
              hasGiftEmoji
              tag={feature_information || featureInformationDefault}
              limit={limit}
              preApproved={preApproved}
              isCompressedLayout={true}
            />
            <CardOffer.Header
              isCardCarousel
              logo={image_logo?.filename}
              logoSize={isSmallImage ? 'default' : 'large'}
              name={title}
              subtitle={subtitle}
              description={editedDescription}
              category={category}
              limit={limit}
              alt={image_logo?.alt || `Foto ilustrativa de ${title}`}
              slug={slug}
            />
            <div className="offer-carousel-card">
              <Conditional
                condition={!content?.detail_page}
                renderIf={
                  <CardOffer.Details
                    toggleDetails
                    details={highlights_ac}
                    name={partner}
                    value={value}
                    changeCarousel={changeCarousel}
                    slug={slug}
                  />
                }
              />
              <div className="offer-carousel-card__button">
                <CardOffer.Button
                  loading={thisBtnLoading}
                  title={button_text}
                  onClick={onClickBtn}
                />
                <Conditional
                  condition={
                    content?.detail_page && content?.partner_friendly_name
                  }
                  renderIf={
                    <CardOffer.Button
                      title="Ver detalhes"
                      type="secondary"
                      onClick={handleDetailRedirect}
                    />
                  }
                />
                <div className="offer-carousel-card__disclaimer">
                  {subject_to_credit_approval && (
                    <CardOffer.Disclaimer hasMargin />
                  )}
                </div>
              </div>
            </div>
          </CardOffer.Root>
        }
      />
    </div>
  );
}
